import React from "react"
import {timeline__content__mobile, timeline__heading} from "../../styles/timeline.module.css"

const TimelineCardMobile = ({data}) => {
  return (
    <div className="flex gap-y-2 flex-col">
      <p className={`sub-heading regular ${timeline__heading}`}>{data.heading}</p>
      <p className={`paragraph2 regular ${timeline__content__mobile}`}>
        {data.content}
      </p>
    </div>
  )
}

export default TimelineCardMobile
