import React from "react"
import TimelineCard from "./TimelineCard"
import {
  border,
  circle,
  border_mobile,
  circle_mobile,
} from "../../styles/timeline.module.css"
import AppButton from "../AppButton"
import TimelineCardMobile from "./TimelineCardMobile"
import achievements from "../../data/achievements.json"
import { navigate } from "gatsby"

const Timeline = ({ showRead }) => {
  const visible = showRead ? achievements.slice(0,5) : achievements;
  return (
    <div className="relative px-4 sm:px-8 md:px-12 lg:px-16 pb-6 py-6 md:py-10 ">
      <img
        src="/timeline-right.png"
        className="absolute top-10 right-0 hidden lg:block"
        alt="timeline"
      />
      <img
        src="/timeline-left.png"
        className="absolute bottom-10 left-0 hidden lg:block"
        alt="timeline"
      />
      <div className="hidden lg:block">
        {
          // Text fontsize changed from heading4 to text-5xl
        }
        <p className={`mb-6 md:mb-10 heading`}>Our Achievements</p>
        <div className="relative wrap overflow-hidden h-full w-full">
          <div
            className={`absolute h-full ${border}`}
            style={{ left: "50%" }}
          ></div>
          {visible.map(achievement => (
            <div
              className={`mb-8 flex justify-center items-center w-full right-timeline ${
                achievement.id % 2 === 0 ? "" : "flex-row-reverse"
              }`}
            >
              <div className="order-1 w-5/12"></div>
              <div
                className={`bg-white z-20 flex items-center order-1 w-6 h-6 rounded-full ${circle}`}
              ></div>
              <TimelineCard left={achievement.id % 2 !== 0} data={achievement} />
            </div>
          ))}
        </div>
        {showRead && (
          <div className="flex justify-center">
            <AppButton
              title="View All"
              onClick={() => navigate("/achievements")}
            />
          </div>
        )}
      </div>
      <div className="block lg:hidden">
        <p className={`mb-6 heading`}>Our Achievements</p>
        <div className="relative wrap overflow-hidden h-full w-full">
          <div
            className={`absolute border-gray-700 h-full ${border_mobile}`}
            style={{ left: "11px" }}
          ></div>
          {achievements.map(achievement => (
            <div className="mb-4 flex items-start gap-x-5 w-full right-timeline">
              <div className="z-20">
                <div
                  className={`bg-white z-20 flex items-center w-6 h-6 rounded-full ${circle_mobile}`}
                ></div>
              </div>
              <TimelineCardMobile data={achievement}/>
            </div>
          ))}
        </div>
        {showRead && (
          <div className="flex justify-start">
            <AppButton
              title="View All"
              onClick={() => navigate("/achievements")}
              size="small"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Timeline
