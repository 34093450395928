import React from "react"
import {Helmet} from "react-helmet";

import Banner from "../components/Banner"
import Layout from "../components/Layout"
import Timeline from "../components/Timeline/Timeline"

const Achievements = () => {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>PICT ACM Student Chapter | Achievements</title>
          <meta name="description" content="PICT ACM Student Chapter(PASC), a student chapter organization subsidiary of the Association for Computing Machinery(ACM), consists of highly motivated students, ready to learn and help each other bring the best out of them. PASC began in 2011, with the perspective of fostering technical and non-technical qualities in an individual and helping them to shape their future." />
      </Helmet>
      <Banner
        title="PASC Achievements"
        text={`PICT ACM Student Chapter aims to solve the problems of the future in the present with technical proficiency. We emphasize the learning experience of our members by conducting activities like Workshops, Guest Lectures, Events, etc.`}
        image="about1.png"
      />
      <div>
        <Timeline />
      </div>
      <img
        src="/testimonials-bottom.png"
        className="w-full hidden md:block"
        alt="Achievements"
      />
    </Layout>
  )
}

export default Achievements
